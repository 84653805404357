import { Button, createMuiTheme, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ThemeProvider, Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React from 'react'
import { connect } from 'react-redux'
import * as Types from '../store/types' 
import {Link} from 'react-router-dom'

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#aa0202'
      },
      secondary: {
        main: '#aa0202'
      }
    }
  })

function Cart(props) {

    function removeItem(cartItemKey){
        props.deleteItem(cartItemKey)
    }

    function renderPriceContiner(price){
        return(
            <>
                <Typography style={{textAlign: 'center', fontSize:17}}>${(Math.round(price * 100) / 100).toFixed(2)}</Typography>

            </>
        )
    }

    function renderOptionsContainer(options, quantity){
        let optionsContainer = [<Typography style={{textAlign: 'left', fontWeight:'300', fontSize:14, color:'grey'}}>Qty: {quantity}</Typography>]
        for (let i=0; i<options.length; i++){
            let optionCategory = options[i].optionItems
            for(let j=0; j<optionCategory.length; j++){
                let optionItem = optionCategory[j]
                optionsContainer.push(
                    <Typography style={{textAlign: 'left', fontWeight:'300', fontSize:14, color:'grey'}}>{optionItem.name}{optionItem.price != '0.00' ? " ($"+((Math.round(optionItem.price * 100) / 100).toFixed(2))+")" : "" }{(i==options.length-1 && (j==optionCategory.length-1 || optionCategory.length==0)) ? '' : ", "}</Typography>
                )
            }
        }
        return(
            optionsContainer
        )
    }
    
    function renderCart(){
        let cartContainer = []
        for (let i=0; i<props.cart.length; i++){
            let cartItem = props.cart[i]
            cartContainer.push(
                <>
                    <ListItem id={i} divider={true}>
                        <ListItemIcon>
                        <ThemeProvider theme={theme}>
                            <IconButton color='primary' onClick={() => {removeItem(cartItem.key)}}>
                                <Delete/>
                            </IconButton>
                        </ThemeProvider>
                        </ListItemIcon>
                        <ListItemText disableTypography={true} style={{fontSize: 17, fontWeight: '400', color: 'black'}} primary={cartItem.name} secondary={renderOptionsContainer(cartItem.options, cartItem.quantity)} ></ListItemText>
                        <ListItemSecondaryAction>
                            {renderPriceContiner(cartItem.total, cartItem.key)}
                        </ListItemSecondaryAction>
                    </ListItem>
                </>
            )
            
        }
    
        return(
            cartContainer
        )
    }
    
    function cartRenderHasItems(){
        return(
            <>
                       
                        <div style={{paddingBottom: 20}}>
                            <Typography style={{fontWeight: 'bold', fontSize: 26, paddingLeft: 10, marginBottom: 10}}>Your Cart</Typography>
                            <Typography style={{fontSize: 15, paddingLeft: 10}}>Ordering from </Typography>
                            <Typography style={{fontSize: 15, paddingLeft: 10}}>jjj</Typography>
                            <Typography style={{fontSize: 15, marginBottom: 10, paddingLeft: 10}}>jjjj</Typography>
                        </div>

                        {renderCart()}
    
                        <ListItem id='subtotal' divider={true}>
                            <ListItemText disableTypography={true} style={{fontSize: 17, fontWeight: '400', color: 'black'}} primary={`Subtotal`} ></ListItemText>
                            <ListItemSecondaryAction>
                                <Typography style={{textAlign: 'center', fontSize:17}}>${(Math.round(props.cartTotal  * 100) / 100).toFixed(2)}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem id="tax" divider={true}>
                            <ListItemText disableTypography={true} style={{fontSize: 17, fontWeight: '400', color: 'black'}} primary={"Tax (13%)"} ></ListItemText>
                            <ListItemSecondaryAction>
                                <Typography style={{textAlign: 'center', fontSize:17}}>${(Math.round(props.cartTotal * 0.13 * 100) / 100).toFixed(2)}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem id="total" divider={true}>
                            <ListItemText disableTypography={true} style={{fontSize: 17, fontWeight: '400', color: 'black'}} primary={"Total"} ></ListItemText>
                            <ListItemSecondaryAction>
                                <Typography style={{textAlign: 'center', fontSize:17}}>${(Math.round(props.cartTotal * 1.13 * 100) / 100).toFixed(2)}</Typography>
                            </ListItemSecondaryAction>
                        </ListItem>
    
                        <div style={{marginBottom: 75}}/>

                        <div style={{position:'fixed', bottom: 10, left: 10, right: 10}}>
                            <Link to='checkout' style={{textDecoration:'none'}}>
                                <Button
                                    style={{backgroundColor:'black', padding: 10, color:'white', width:'100%'}}
                                    variant="contained">
                                    Go To Checkout
                                </Button>
                            </Link>
                        </div>
        </>
        )
    }



    return (
        <div>

            {props.cart.length > 0 ? cartRenderHasItems() : cartRenderHasItems()}

        </div>
    )
}

//REDUX
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deleteItem: cartItemKey => dispatch({type: Types.REMOVE_ITEM, payload: {
        cartItemKey
    }})
});
const connectComponent = connect(mapStateToProps, mapDispatchToProps)

export default connectComponent(Cart)
