export default {
    Constants: {
        BASE_URL: "http://app.dishmenus.com/api/v1/",
        GET_MENU: 'menu/get/',
    },
    FirebaseConfig: {
        apiKey: "AIzaSyAeZJcQAAA-nswpZgFj1tnV1cB-BzR7hKs",
        authDomain: "dishmenus-5b84a.firebaseapp.com",
        projectId: "dishmenus-5b84a",
        storageBucket: "dishmenus-5b84a.appspot.com",
        messagingSenderId: "469753588023",
        appId: "1:469753588023:web:19f4d6fb3f5ec0afecd2b2"
    }
}