import React from 'react'
import { Button, ThemeProvider, createMuiTheme, TextField, InputAdornment, Typography, FormHelperText } from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'

//FIREBASE
import firebase from 'firebase'

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#000'
      }
    }
  });

function Login(props) {

    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [verificationCode, setVerificationCode] = React.useState('');
    
    const [verificationId, setVerificationId] = React.useState();

    const [numberError, setNumberError] = React.useState()
    const [otpError, setOtpError] = React.useState()

    function didTapNext(e){
        if(!window.recaptchaVerifier){
            window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container",
            {
                size:"invisible"
            });
        }

        if(phoneNumber != null && phoneNumber.length == 10){
            const appVerifier = window.recaptchaVerifier;
            firebase.auth().signInWithPhoneNumber("+1"+phoneNumber, appVerifier)
            .then((confirmationResult) => {
                setVerificationId(confirmationResult);
            }).catch((error) => {
                setNumberError(error.message)
            })
        }
        else{
            setNumberError("Enter a valid phone number")
        }
    }

    function didTapVerify(e) {
        if(verificationCode != null && verificationCode.length == 6){
            verificationId.confirm(verificationCode).then((result) => {
                props.onCloseModal()
              }).catch((error) => {
                setOtpError(error.message)
              });
        }
        else{
            setOtpError("Enter a valid verification code")
        }
        
    }

    function renderPhoneScreen() {
        return (
            <ThemeProvider theme={theme}>
                <div style={{padding: 20, height:'100%', paddingTop: 10}}>
                    <Typography style={{fontWeight: '500', fontSize: 20, marginBottom: 20}}>Enter your phone number to order</Typography>
                    <div style={{marginBottom: 20}}>
                        <TextField
                            inputProps={{
                                maxLength: 10,
                            }}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment>
                                    <Typography style={{paddingRight: 10}}>CA +1</Typography>
                                </InputAdornment>
                                )
                            }}
                            error={!!numberError}
                            value={phoneNumber}
                            autoFocus='true'
                            fullWidth='true'
                            maxLength={10}
                            variant="outlined"
                            label="Phone Number"
                            onChange={(e) => {setPhoneNumber(e.target.value)}}>
                        </TextField>
                        <FormHelperText error={true} visible={!!numberError}>
                            {numberError}
                        </FormHelperText>
                    </div>

                    <Button
                        contentStyle={{flexDirection: 'row-reverse'}}
                        style={{ backgroundColor:'#aa0202', marginTop:50, color:'#fff', width:'100%'}}
                        variant="contained"
                        onClick={(e) => {didTapNext(e)}}
                        endIcon={<ArrowForward/>}>
                        Next
                    </Button>
                    
                </div>
                
            </ThemeProvider>
        )
    }

    function renderOtpScreen() {
        return (
            <ThemeProvider theme={theme}>
                <div style={{padding: 20, height:'100%', paddingTop: 10}}>
                    <Typography style={{fontWeight: '500', fontSize: 20, marginBottom: 20}}>Verify your phone number</Typography>
                    <Typography style={{fontWeight: '400', fontSize: 15, marginBottom: 20, color:'black'}}>Enter the 6 digit code sent to {phoneNumber}</Typography>
                    <div style={{marginBottom: 20}}>
                        <TextField
                            inputProps={{
                                maxLength: 6,
                            }}
                            error={!!otpError}
                            value={verificationCode}
                            autoFocus={true}
                            fullWidth={true}
                            variant="outlined"
                            onChange={(e) => {setVerificationCode(e.target.value)}}>
                        </TextField>
                        <FormHelperText error={true} visible={!!otpError}>
                            {otpError}
                        </FormHelperText>
                    </div>

                    <Button
                        contentStyle={{flexDirection: 'row-reverse'}}
                        style={{ backgroundColor:'#aa0202', marginTop:50, color:'#fff', width:'100%'}}
                        variant="contained"
                        onClick={(e) => {didTapVerify(e)}}
                        endIcon={<ArrowForward/>}>
                        Next
                    </Button>
                    
                </div>
                
            </ThemeProvider>
        )
    }

    return (
        <>
            <div id="recaptcha-container"></div>
            {(!!verificationId) ? renderOtpScreen() : renderPhoneScreen()}
        </>
    )
}

export default Login
