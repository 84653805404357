import { TextField, Typography, createMuiTheme, ThemeProvider, FormHelperText, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Button, Dialog, DialogTitle, DialogContent, CircularProgress } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import * as Types from '../store/types' 
import firebase from 'firebase'

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#000'
      }
    }
  });

function Checkout(props) {
    
    const user = firebase.auth().currentUser;

    const [name, setName] = React.useState("")
    const [tableNo, setTableNo] = React.useState("1")
    const [note, setNote] = React.useState("")
    const [secureCode, setSecureCode] = React.useState("")

    const [nameError, setNameError] = React.useState("")
    const [tableNoError, setTableNoError] = React.useState("")
    const [secureCodeError, setsecureCodeError] = React.useState("")

    const [isPlacingOrder, setIsPlacingOrder] = React.useState(false)


    function onCheckoutPressed(){

        var isError = false;

        if (name.trim() == ""){
            setNameError("Name is required.")
            isError = true
        }
        if (tableNo.trim() == "" || tableNo < 1){
            setTableNoError("Invalid table number. ")
            isError = true
        }
        if (secureCode.trim() == "" || secureCode < 1000 || secureCode > 9999){
            setsecureCodeError("Enter a 4 digit code. ")
            isError = true
        }

        if(!isError){
            let order = {
                'secure_code': secureCode,
                'restaurant_id': 1,
                'first_name': name,
                'last_name': "",
                'street': "",
                'city': "",
                'province': "",
                'postal': "",
                'phone': user.phoneNumber,
                'email': "",
                'order_note': note,
                'delivery': "Dine-In",
                'delivery_cost': "0",
                'total': (Math.round(props.cartTotal * 1.13 * 100) / 100).toFixed(2),
                'items': props.cart,
            }

            setIsPlacingOrder(true)

            //FETCH DATA START
            fetch("https://app.dishmenus.com/api/v1/order/place", {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(order)
            })
            .then(response => response.json())
            .then((responseJson) => dataResponse(responseJson))
            .catch(error => console.log(error))
            .finally(() => setIsPlacingOrder(false), props.clearCart())
            //FETCH DATA START
        }
    }

    function dataResponse(responseJson){
        //console.log(responseJson);
    }

    return (
        <>
            <div style={{paddingBottom: 5}}>
                <Typography style={{fontWeight: 'bold', fontSize: 26, paddingLeft: 10, marginBottom: 10}}>Checkout</Typography>
                <Typography style={{fontSize: 15, paddingLeft: 10}}>Ordering from </Typography>
                <Typography style={{fontSize: 15, paddingLeft: 10}}>jjj</Typography>
                <Typography style={{fontSize: 15, marginBottom: 10, paddingLeft: 10}}>jjjj</Typography>
            </div>
            
            <ThemeProvider theme={theme}>
                <div style={{padding: 20}}>
                    <FormHelperText style={{marginBottom:10}} error={true} visible={!!nameError || !!tableNoError}>
                            {nameError+" "+tableNoError+" "+secureCodeError}
                    </FormHelperText>
                    <div style={{display:'flex', paddingBottom:10}}>
                        <TextField
                            error={!!nameError}
                            style={{paddingRight:5}}
                            value={name}
                            autoFocus='true'
                            fullWidth='true'
                            variant="outlined"
                            label="Name"
                            onChange={(e) => {
                                                setNameError("")
                                                setName(e.target.value)
                                            }}>
                        </TextField>
                        <TextField
                            error={!!tableNoError}
                            inputProps={{min: 1}}
                            type='number'
                            style={{paddingLeft:5}}
                            value={tableNo}
                            fullWidth='true'
                            variant="outlined"
                            label="Table Number"
                            onChange={(e) => {
                                                setTableNoError("")
                                                setTableNo(e.target.value)
                                            }}>
                        </TextField>
                    </div>
                    <TextField
                        style={{paddingBottom:10}}
                        value={note}
                        fullWidth='true'
                        variant="outlined"
                        label="Note"
                        onChange={(e) => {setNote(e.target.value)}}>
                    </TextField>
                    <TextField
                        error={!!secureCodeError}
                        value={secureCode}
                        inputProps={{min: 1000, max: 9999}}
                        type='number'
                        fullWidth='true'
                        variant="outlined"
                        FormHelperText="ddd"
                        label="Secure Code"
                        onChange={(e) => {
                                            setsecureCodeError("")
                                            setSecureCode(e.target.value)
                                        }}>
                    </TextField>
                    <FormHelperText error={false} visible={true}>
                            A 4 digit secure pin will be displayed nearby in the restaurant
                    </FormHelperText>
                </div>
            </ThemeProvider>

            <div>
                <ListItem id='subtotal' divider={true}>
                    <ListItemText disableTypography={true} style={{fontSize: 17, fontWeight: '400', color: 'black'}} primary={`Subtotal`} ></ListItemText>
                    <ListItemSecondaryAction>
                        <Typography style={{textAlign: 'center', fontSize:17}}>${(Math.round(props.cartTotal  * 100) / 100).toFixed(2)}</Typography>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem id="tax" divider={true}>
                    <ListItemText disableTypography={true} style={{fontSize: 17, fontWeight: '400', color: 'black'}} primary={"Tax (13%)"} ></ListItemText>
                    <ListItemSecondaryAction>
                        <Typography style={{textAlign: 'center', fontSize:17}}>${(Math.round(props.cartTotal * 0.13 * 100) / 100).toFixed(2)}</Typography>
                    </ListItemSecondaryAction>
                </ListItem>
                <ListItem id="total" divider={true}>
                    <ListItemText disableTypography={true} style={{fontSize: 17, fontWeight: '400', color: 'black'}} primary={"Total"} ></ListItemText>
                    <ListItemSecondaryAction>
                        <Typography style={{textAlign: 'center', fontSize:17}}>${(Math.round(props.cartTotal * 1.13 * 100) / 100).toFixed(2)}</Typography>
                    </ListItemSecondaryAction>
                </ListItem>
            </div>

            <div style={{marginLeft: 10, marginRight: 10, marginTop: 50}}>
                <Button
                    style={{backgroundColor:'green', padding: 10, color:'white', width:'100%'}}
                    variant="contained"
                    onClick={() => {onCheckoutPressed()}}>
                    Place Order
                </Button>
            </div>
            <Dialog
                open={isPlacingOrder}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{"Placing Order..."}</DialogTitle>
                    <DialogContent style={{alignSelf:'center'}}>
                        <ThemeProvider theme={theme}><CircularProgress/></ThemeProvider>
                    </DialogContent>
            </Dialog>
        </>
    )
}

//REDUX
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    deleteItem: cartItemKey => dispatch({type: Types.REMOVE_ITEM, payload: {
        cartItemKey
    }}),
    clearCart: () => dispatch({type: Types.CLEAR_CART})
});
const connectComponent = connect(mapStateToProps, mapDispatchToProps)

export default connectComponent(Checkout)
