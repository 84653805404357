import * as Types from './types'

const initialState = {
    cart: [],
    cartTotal: 0,
    cartItemKeyer: 0,
    cartRestaurant: [],
    cartRestaurantKey: "",
    cartRestaurantName: "",
    cartRestaurantStreet: "",
    cartRestaurantCity: "",
    cartRestaurantPostal: ""
}

const reducer = (state = initialState, action) => {

    switch(action.type) {

        case Types.ADD_ITEM:
            return {  ...state,
                cart: [...state.cart, action.payload.item],
                cartTotal: state.cartTotal + action.payload.item.total,
                cartItemKeyer: state.cartItemKeyer + 1,
                cartRestaurantKey: action.payload.restaurant.key,
                cartRestaurantName: action.payload.restaurant.name,
                cartRestaurantStreet: action.payload.restaurant.street,
                cartRestaurantCity: action.payload.restaurant.city,
                cartRestaurantPostal: action.payload.restaurant.postal,
                cartRestaurant: action.payload.restaurant
            }
        
        case Types.REMOVE_ITEM:
            let newCart = [...state.cart]
            let newCartTotal = state.cartTotal
            for(let i=0; i<newCart.length; i++){
                let item = newCart[i]
                if(item.key == action.payload.cartItemKey){
                    newCartTotal = newCartTotal-item.total
                    newCart.splice(i,1);
                }
            }

            if(newCart.length == 0){
                return{ ...state,
                    cart: [],
                    cartTotal: 0,
                    cartItemKeyer: 0,
                    cartRestaurantKey: "",
                    cartRestaurantName: "",
                    cartRestaurantStreet: "",
                    cartRestaurantCity: "",
                    cartRestaurantPostal: "",
                    cartRestaurant: []
                }
            }
            else{
                return { ...state,
                    cart: newCart,
                    cartTotal: newCartTotal
                }
            }
            
        case Types.CLEAR_CART:
            return{ ...state,
                cart: [],
                cartTotal: 0,
                cartItemKeyer: 0,
                cartRestaurantKey: "",
                cartRestaurantName: "",
                cartRestaurantStreet: "",
                cartRestaurantCity: "",
                cartRestaurantPostal: "",
                cartRestaurant: []
            }

        default:
            return state;
    }

}

export { reducer }