import { Button, IconButton, Checkbox, Radio, RadioGroup, Typography } from '@material-ui/core'
import React from 'react'
import { Add, Remove } from '@material-ui/icons'

import { connect } from 'react-redux'
import * as Types from '../store/types' 

function MenuOptionScreen(props) {
    const restaurant = {}
    const item = (props.item)
    const itemOptionsArray = item.options //contains all options array
    const [options, setOptions] = React.useState(itemOptionsArray)
    const [quantity, setQuantity] = React.useState(1)

    //INITAL VARIABLE GATHERING
    let initialTotal = Number(item.price)
    options.map((optionCategory) => {
        optionCategory.optionItems.map((optionItem) => {
            if(optionItem.checked == 1){
                initialTotal = Number(initialTotal)+Number(optionItem.price)
            }
        })
    })

    const [total, setTotal] = React.useState(initialTotal)

    function updateTotal(){
        let totalLocalUpdate = Number(item.price)
        options.map((optionCategory) => {
            optionCategory.optionItems.map((optionItem) => {
                if(optionItem.checked == 1){
                    totalLocalUpdate = Number(totalLocalUpdate)+Number(optionItem.price)
                }
            })
            setTotal(totalLocalUpdate)
        })
    }

    function onChecked(categoryIndex, optionIndex){
        const data = [...options]
        data[categoryIndex].optionItems[optionIndex].checked = !Number(data[categoryIndex].optionItems[optionIndex].checked)
        console.log(data[categoryIndex])
        setOptions(data)

        updateTotal()
        
    }

    function onRadioCheck(categoryIndex, optionIndex){
        const data = [...options]
        
        for(let i=0; i<data[categoryIndex].optionItems.length; i++){
            if(i==optionIndex){
                data[categoryIndex].optionItems[i].checked = 1
            }
            else{
                data[categoryIndex].optionItems[i].checked = 0
            }
        }
        setOptions(data)

        updateTotal()
    }

    function renderOptions() {
        const optionsMainDiv = []
        options.map((optionCategory, mainIndex) => {
            const subDiv = []
            subDiv.push(
                <Typography key={optionCategory.key+"-title"} style={{padding: 10, backgroundColor: '#cdcdcd', fontWeight: '600',}}>{optionCategory.name}</Typography>   
            )
            if(optionCategory.type == 'radio'){
                let ff = optionCategory.optionItems.map((optionItem, subIndex) => {
                    return(
                        <div style={{flexDirection:"row", alignItems:'center', justifyContent:'space-between', display:'flex'}} onClick={() => onRadioCheck(mainIndex, subIndex)}>
                            <div style={{flex:7}}>
                                <Typography style={{marginLeft: 10, fontSize: 16}}>{optionItem.price != '0.00' ? optionItem.name+'   (+$'+optionItem.price+')' : optionItem.name}</Typography>
                            </div>
                            <div style={{flex:1}}>
                                <Radio
                                    onChange={() => onRadioCheck(mainIndex, subIndex)}
                                    key= {optionItem.key}
                                    checked={Number(options[mainIndex].optionItems[subIndex].checked) ? true : false}
                                    />
                            </div>
                        </div>
                    )
                })
                subDiv.push(
                    <RadioGroup key={optionCategory.key}>
                        {ff}
                    </RadioGroup>
                )
            }
            else if(optionCategory.type == 'checkbox'){
                let ff = optionCategory.optionItems.map((optionItem, subIndex) => {
                    return(
                            <div style={{flexDirection:"row", alignItems:'center', justifyContent:'space-between', display:'flex'}} onClick={() => onChecked(mainIndex, subIndex)}>
                                <div style={{flex:7}}>
                                    <Typography style={{marginLeft: 10, fontSize: 16}}>{optionItem.price != '0.00' ? optionItem.name+'   (+$'+optionItem.price+')' : optionItem.name}</Typography>
                                </div>
                                <div style={{flex:1}}>
                                    <Checkbox
                                        onChange={() => onChecked(mainIndex, subIndex)}
                                        key= {optionItem.key}
                                        checked={Number(options[mainIndex].optionItems[subIndex].checked) ? true : false}
                                        />
                                </div>
                            </div>
                        
                    )
                })
                subDiv.push(
                    <div key={optionCategory.key} style={{paddingBottom:10}}>
                        {ff}
                    </div>
                )
            }
            
            optionsMainDiv.push(subDiv)
        })

        return optionsMainDiv
    }



    function addToCart() {

        if(props.cartRestaurantKey != restaurant.key && props.cartRestaurantKey != ""){
            //showDialog()
        }
        else{
            let selectedOptions = []
            options.map((optionCategory) => {
                const selectedOptionsSub = optionCategory.optionItems.filter(optionItem => optionItem.checked == 1);
                selectedOptions.push(
                    {
                        key:optionCategory.key,
                        name:optionCategory.name,
                        type:optionCategory.type,
                        optionItems: selectedOptionsSub
                    }
                )
            })


            let cartItem = {
                key: props.cartItemKeyer,
                itemKey: item.key,
                name: item.name,
                price: item.price,
                quantity: quantity,
                total: quantity * total,
                options: selectedOptions
            }
            
            props.addItem(cartItem, restaurant)
            props.onCloseModal()
            //setSnackBarText("✓ Added to Cart")
            //onToggleSnackBar()
            //console.log(props)

        }

        
    }

    return (
        <div>
            <div style={{paddingBottom: 20}}>
                <Typography style={{fontWeight: 'bold', fontSize: 26, paddingLeft: 10, marginBottom: 10}}>{item.name}</Typography>
                <Typography style={{fontSize: 15, color: 'grey',  marginBottom: 10, paddingLeft: 10}}>{item.description}</Typography>
            </div>
            {renderOptions()}
            <div style={{height:30}}></div>
            <Typography key={"total"} style={{ color:'#ffffff', textAlign:'center', padding: 10, backgroundColor: '#aa0202', fontWeight: '600',}}>${(Math.round(quantity * total * 100) / 100).toFixed(2)}</Typography>   
            {
                1==1
                ?
                <></>
                :
                <>
                <div style={{display:'flex', justifyContent:'center', flex:1, flexDirection:'row', padding: 20, marginBottom: 75}}>
                    <div style={quantity == 1 ? {backgroundColor: '#edebeb', borderRadius: 50} : {backgroundColor: 'lightgrey', borderRadius: 50}} >
                        <IconButton  disabled={ quantity == 1 ? true : false } color='black' labelStyle={{fontSize:25}} style={{borderRadius:50}} onClick={() => setQuantity(quantity-1)}><Remove/></IconButton>
                    </div>
                    <Typography style={{padding: 10, fontSize: 20}}>  {quantity}  </Typography>
                    <div style={{backgroundColor: 'lightgrey', borderRadius: 50}} >
                        <IconButton color='black' labelStyle={{fontSize:25}} style={{borderRadius:50}} onClick={() => setQuantity(quantity+1)}><Add/></IconButton>
                    </div>                    
                </div>
                <div style={{position:'fixed', bottom: 10, left: 10, right: 10}}>
                    <Button
                        onClick={() => {addToCart()}}
                        style={{backgroundColor:'black', padding: 10, color:'white', width:'100%'}}
                        icon="shopping"
                        variant="contained">
                        Add {quantity} to Cart (${(Math.round(quantity * total * 100) / 100).toFixed(2)})
                    </Button>
                </div>
                </>
            }
            
            
        </div>
    )
}

//REDUX
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({
    addItem: (item, restaurant) => dispatch({type: Types.ADD_ITEM, payload: {
        item, restaurant
    }}),
    clearCart: () => dispatch({type: Types.CLEAR_CART})
});
const connectComponent = connect(mapStateToProps, mapDispatchToProps)

export default connectComponent(MenuOptionScreen)

