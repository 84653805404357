import React from 'react'

import { BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom'

import Index from './pages/Index'
import Cart from './pages/Cart'
import Checkout from './pages/Checkout'
import NotFound from './pages/NotFound'

//REDUX
import { Provider } from 'react-redux'
import { store } from './store/store';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/restaurant/:slug" component={Index} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App;
