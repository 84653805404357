import React from 'react'
import { Avatar , Button, AppBar, ThemeProvider, createMuiTheme, IconButton, Toolbar, CircularProgress, Typography, Container, Grid, Chip, Divider, Dialog } from '@material-ui/core'
import Login from './Login'
import MenuOptionScreen from './MenuOptionScreen'
import { Close } from '@material-ui/icons'
import firebase from 'firebase'
import Keys from '../keys/key'
import { Link, useParams, Redirect} from 'react-router-dom'

import { connect } from 'react-redux'

const theme_dd = createMuiTheme({
  palette: {
    primary: {
      main: '#aa0202'
    },
    secondary: {
      main: '#aa0202'
    }
  }
})

function Index(props) {

    const { slug } = useParams();

    //AUTH STATE
    const [isAuthenticationReady, setIsAuthenticationReady] = React.useState(false)
    const [isAuthenticated, setIsAuthenticated] = React.useState(false)
    const [color, setColor] = React.useState("aa0202")
    const [theme, setTheme] = React.useState(theme_dd)

    if(!firebase.apps.length){
        firebase.initializeApp(Keys.FirebaseConfig)
    }
    else{
        firebase.app()
    }

    firebase.auth().onAuthStateChanged(onAuthStateChanged);

    function onAuthStateChanged(user) {
        setIsAuthenticationReady(true);
        setIsAuthenticated(!!user)
    }

    let user = firebase.auth().currentUser

    const [isLoading, setIsLoading] = React.useState(true)
    const [menu, setMenu] = React.useState([])

    const [selectedItem, setSelectedItem] = React.useState()

    const [restaurant, setRestaurant] = React.useState()

    const [isLoginOpen, setIsLoginOpen] = React.useState(false)
    function handleCloseLogin(){
        setIsLoginOpen(false)
    }

    const [isOptionsOpen, setIsOptionsOpen] = React.useState(false)
    function handleCloseOptions(){
        setIsOptionsOpen(false)
        setSelectedItem()
    }

    React.useEffect(() => {
      fetch(" https://app.dishmenus.com/api/v1/restaurants/get/start-up-web/"+slug, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: []
        })
        .then(response => response.json())
        .then(responseJson => {
          if(responseJson.code == "404"){
            window.location.href = '/404'; //relative to domain
          }
          setRestaurant(responseJson.data)
          //FETCH DATA START
          fetch("https://app.dishmenus.com/api/v1/menu/get/"+responseJson.data.id, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: []
            })
            .then(response => response.json())
            .then((responseJson) => setMenu(responseJson.data))
            .catch(error => console.log(error))
            .finally(() => setIsLoading(false))
        })
        .catch(error => console.log(error))
        //FETCH DATA START

    }, [])

    if (isLoading || !isAuthenticationReady){
        return (
        renderLoading()
        )
    }
    else{
        return (
        renderLoaded()
        )    
    }


  function renderLoading() {
    return (
      <ThemeProvider theme={theme}>
        <Grid>
          <CircularProgress/>
        </Grid>
      </ThemeProvider>
    )
  }


  function renderChips() {
    let chips = []
        menu.map((category, index) => {
            chips.push(
                <Chip className='chip' label={category.title.trim()} onClick={() => {window.location = "#"+category.title.replace(/\s/g, '')}} />
            )
        })
        return(chips)
  }

  function renderOptions(item){
    let optionsContainer = []
    let options = item.options
    options.map((optionCategory) => {
      let optionsString = optionCategory.name+": "
      optionCategory.optionItems.map((option, index) => {
        optionsString = optionsString+option.name
        if(option.price != '0.00'){
          optionsString = optionsString+' (+ $'+option.price+')'
        }
        console.log(optionCategory.optionItems.count)
        if(optionCategory.optionItems.length-1 != index){
          optionsString = optionsString+", "
        }
      })
      optionsContainer.push(
        <Typography style={{fontSize: 12, paddingBottom: 5}}>{optionsString}</Typography>
      )
    })

    return optionsContainer
  }

  function renderMenu(){
    let menuContainer = []
    menu.map((category, index) => {
        let categoryItems = []
        category.data.map((item) => {
            categoryItems.push(
                    <>
                    {1==1

                    ?
                        <>
                            {/*<div className="itemContainer" onClick={() => {
                                                                              setSelectedItem(item)
                                                                              setIsOptionsOpen(true)
                                                                        }
                                                                    }>*/}
                              <div className="itemContainer" onClick={() => {}}>
                                <div className="informationContainer">
                                  <Typography style={{fontSize: 15, paddingBottom: 5}}>{item.name}</Typography>
                                  <Typography style={{fontSize: 12, paddingBottom: 5}}>{item.description}</Typography>
                                  {renderOptions(item)}
                                </div>
                                <div className="otherContainer">
                                <Typography style={{fontSize: 15, paddingBottom: 5}}>${(Math.round(item.price * 100) / 100).toFixed(2)}</Typography>
                                </div>
                            </div>
                        </>

                    :
                    
                        <>
                            <div className="itemContainer" onClick={() => {setIsLoginOpen(true)}}>
                                <div className="informationContainer">
                                <Typography style={{fontSize: 15, paddingBottom: 5}}>{item.name}</Typography>
                                <Typography style={{fontSize: 12, paddingBottom: 5}}>{item.description}</Typography>
                                {renderOptions(item)}
                                </div>
                                <div className="otherContainer">
                                <Typography style={{fontSize: 15, paddingBottom: 5}}>${(Math.round(item.price * 100) / 100).toFixed(2)}</Typography>
                                </div>
                            </div>
                        </>
                    }
                      
                      <Divider/>
                    </>
            )
        })

        menuContainer.push(
            <div className="categoryHeadingContainer" id={category.title.replace(/\s/g, '')}>
              <Typography style={{fontWeight: '600', fontSize:14, color:restaurant.secondary_color}}>{category.title}</Typography>
            </div>
        )
        menuContainer.push(categoryItems)
    })
    return(menuContainer)
  } 

  function renderLoaded() {
    return(
      <ThemeProvider theme={createMuiTheme({
        palette: {
          primary: {
            main: restaurant.primary_color
          },
          secondary: {
            main: restaurant.primary_color
          }
        }
      })} >
        <AppBar position='static' style={{background: 'url("https://app.dishmenus.com/api/v1/restaurants/get/banner/'+restaurant.id+'")', backgroundSize:'cover'}}>
          <Toolbar style={{background: 'rgb(0 0 0 / 70%)'}}>
            <Grid item xs={12} style={{paddingTop: 50, paddingBottom: 50, alignItems:'center'}}>
              <div style={{justifyContent:'center', display:'flex'}}>
                <Avatar style={{width:100, height:100, background:'#ffffff'}} src={'https://app.dishmenus.com/api/v1/restaurants/get/logo/'+restaurant.id}></Avatar>
              </div>
              <Typography variant="h5" style={{textAlign:'center'}}>{restaurant.name}</Typography>
              <Typography variant="h6" style={{textAlign:'center'}}>{restaurant.street}</Typography>
            </Grid>
          </Toolbar>
        </AppBar>
        <AppBar position='sticky'>
            <div className='noscrollbar' style={{display:'flex', overflow:'auto', paddingTop: 10, paddingBottom:10}}>
              {renderChips()}
            </div>
        </AppBar>
        <div className="App">
          {renderMenu()}
          
          <div style={{position:'fixed', bottom: 10, left: 10, right: 10}}>
            
          {

            1==1
            ?
            <></>
            :
            restaurant.is_open == 1
            ?
              <Link to='../cart' style={{textDecoration:'none'}}>
                <Button
                    style={{backgroundColor:'black', padding: 10, color:'white', width:'100%'}}
                    icon="shopping"
                    variant="contained">
                    VIEW CART ({props.cart.length} Items)
                </Button>
              </Link>
            :
                <Button
                    style={{backgroundColor:'red', padding: 10, color:'white', width:'100%'}}
                    icon="shopping"
                    variant="contained">
                    Restaurant Closed
                </Button>

          }
          
          </div>
        </div>
        <AppBar position='sticky' style={{top:'auto', bottom: 0}}>
            <div style={{paddingTop: 5, paddingBottom:5}}>
              <Typography style={{textAlign:'center'}}>Powered by DishMenus</Typography>
            </div>
        </AppBar>
        <Dialog fullScreen open={isLoginOpen} onClose={handleCloseLogin}>
            <>
                <div style={{flexDirection:'row', alignSelf:'flex-start', padding: 5}}>
                <IconButton aria-label="delete" onClick={() => {setIsLoginOpen(false)}}>
                    <Close fontSize="inherit" />
                </IconButton>
                </div>
                <Login onCloseModal={handleCloseLogin}/>
            </>
        </Dialog>
        <Dialog fullScreen open={isOptionsOpen} onClose={handleCloseOptions}>
            <>
                <div style={{flexDirection:'row', alignSelf:'flex-start', padding: 5}}>
                <IconButton aria-label="delete" onClick={() => {setIsOptionsOpen(false)}}>
                    <Close fontSize="inherit" />
                </IconButton>
                </div>
                <MenuOptionScreen onCloseModal={handleCloseOptions} item={selectedItem}/>
            </>
        </Dialog>
      </ThemeProvider>
    );
  }
}

//REDUX
const mapStateToProps = state => state;
const mapDispatchToProps = dispatch => ({});
const connectComponent = connect(mapStateToProps, mapDispatchToProps)

export default connectComponent(Index)
