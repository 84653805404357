import React from 'react'
import { ThemeProvider, createMuiTheme, Typography, Grid } from '@material-ui/core'

const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#aa0202'
      },
      secondary: {
        main: '#aa0202'
      }
    }
  })
  
function NotFound() {
    return (
        <ThemeProvider theme={theme}>
            <div style={{ position:'absolute', left:0, right:0, top:0, bottom:0, display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div>
                    <Typography variant="h3" style={{textAlign:'center'}}>404</Typography>
                    <Typography variant="h6" style={{textAlign:'center'}}>The page you are looking for was not found</Typography>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default NotFound
